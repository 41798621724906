/* ==========================================================================
      Main-Colors
   ========================================================================== */

$background-soft3: var(--background-soft3, #ffffff);
$background-soft2: var(--background-soft2, #ffffff);
$background-soft1: var(--background-soft1, #ffffff);
$background: var(--background, #ffffff);
$background-strong1: var(--background-strong1, rgba(250, 250, 250, 1));
$background-strong2: var(--background-strong2, rgba(244, 244, 244, 1));
$background-strong3: var(--background-strong3, rgba(221, 221, 221, 1));

$foreground-soft3: var(--foreground-soft3, white);
$foreground-soft2: var(--foreground-soft2, rgb(213, 213, 213));
$foreground-soft1: var(--foreground-soft1, rgba(102, 102, 102, 1));
$foreground: var(--foreground, black);
$foreground-strong1: var(--foreground-strong1, black);
$foreground-strong2: var(--foreground-strong2, black);
$foreground-strong3: var(--foreground-strong3, black);

$accent1-soft3: var(--accent1-soft3, #FFFF64);
$accent1-soft2: var(--accent1-soft2, #FFFF58);
$accent1-soft1: var(--accent1-soft1, #FFF24C);
$accent1: var(--accent1, #FFD740);
$accent1-strong1: var(--accent1-strong1, #F8CD37);
$accent1-strong2: var(--accent1-strong2, #F1C32E);
$accent1-strong3: var(--accent1-strong3, #EAB925);

$accent2-soft3: var(--accent2-soft3, #fb8d28);
$accent2-soft2: var(--accent2-soft2, #fca14e);
$accent2-soft1: var(--accent2-soft1, #fdbc81);
$accent2: var(--accent2, #fa7902);
$accent2-strong1: var(--accent2-strong1, #f97102);
$accent2-strong2: var(--accent2-strong2, #f96601);
$accent2-strong3: var(--accent2-strong3, #f85c01);

$accent3-soft3: var(--accent3-soft3, green);
$accent3-soft2: var(--accent3-soft2, green);
$accent3-soft1: var(--accent3-soft1, green);
$accent3: var(--accent3);
$accent3-strong1: var(--accent3-strong1, green);
$accent3-strong2: var(--accent3-strong2, green);
$accent3-strong3: var(--accent3-strong3, green);

$info_foreground: var(--info_foreground, yellow);
$info_background: var(--info_background, yellow);

$success_foreground: var(--success_foreground, rgba(126, 160, 67, 1));
$success_background: var(--success_background, rgb(215, 232, 188));

$warning_foreground: var(--warning_foreground, orange);
$warning_background: var(--warning_background, rgb(235, 217, 184));

$error_foreground: var(--error_foreground, rgb(212, 2, 2));
$error_background: var(--error_background, rgb(255, 204, 204));
