/* ==========================================================================
   General styles:
   ========================================================================== */

   body {
    color: var(--foreground);
    font-size: 1em;
    line-height: 1.4;
    font-family: 'Open Sans', sans-serif;
    margin: 0px;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

html, body { height: 100%; }
body { margin: 0; }

p {
  margin: 0px;
}
